import './index.scss'

$('[data-open="cmenu"]').on('click', function () {
    if ($('.header .hamburger').hasClass('active')) {
        $('.hamburger').removeClass('active')
        $('.root-mask-bottom').fadeOut(200)
        $('.header-nav').removeClass('active')
        $('.header-nav ul li .sub-nav').stop().slideUp(200)
        $('.header-nav ul li .sub-nav-title').removeClass('active')
        $(".header .cont .text").html("MENU")
        $(".header .right").css("background", "#C78F42")
        $(".header-nav .pic").css("transform", "translateX(-100%)")
        $(".header-nav .right").css("opacity", "0")
        $(".header-nav .right .nav").css("transform", "translateY(20px)")
        
    } else {
        $(".menu_shadow").addClass('active')
        $('.hamburger').addClass('active')
        $('.root-mask-bottom').fadeIn(200)
        $('.header-nav').addClass('active')
        $(".header .cont .text").html("CLOSE")
        $(".header .right").css("background","transparent")
        setTimeout(function () { 
            $(".header-nav .pic").css("transform", "translateX(0%)")
        }, 300);
        setTimeout(function () {
            $(".header-nav .right").css("opacity", "1")
            $(".header-nav .right .nav").css("transform", "translateY(0)")
        }, 400);
        
    }
})



