import './index.scss'

import webmap from 'jason-webmap'

webmap({
  // 婵€娲 map 鐨勬寜閽€夋嫨鍣╘r
  openSelector: '[data-open="map"]',
  // 璺熼殢鑰 map 涓€鍚岀Щ鍔ㄧ殑鍐呭閫夋嫨鍣╘r
  moveSelector: '.comp-root'
})
